<template>
  <div class="product-page">
    <el-button type="primary" icon="el-icon-plus" @click="handleAdd">新增</el-button>
    <el-table :data="productList" max-height="500">
      <el-table-column prop="productPic" label="产品图片">
        <template slot-scope="scope">
          <img class="mini-pic" :src="`${$host}:7001/public/${scope.row.productPic}`" alt="">
        </template>
      </el-table-column>
      <el-table-column prop="productName" label="产品名称" />
      <el-table-column prop="typeName" label="类型名称" />
      <el-table-column prop="isRecommend" label="推荐展示">
        <template slot-scope="scope">
          <el-switch :value="!!scope.row.isRecommend"></el-switch>
        </template>
      </el-table-column>
      <el-table-column prop="createTime" label="创建时间" :formatter="timeFormatter" />
      <el-table-column label="操作">
        <template slot-scope="scope">
          <el-button size="mini" @click="handleEdit(scope.row)">编辑</el-button>
          <el-popconfirm title="是否删除" @confirm="handleDelete(scope.row.id)">
            <el-button size="mini" slot="reference">删除</el-button>
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>
    <el-dialog title="新增产品" :visible.sync="visible" width="30%" @closed="handleClosed">
      <el-form :model="productForm" inline>
        <el-form-item label="产品名称">
          <el-input v-model="productForm.productName" type="text" />
        </el-form-item>
        <el-form-item label="产品类型">
          <el-select v-model="productForm.typeId" placeholder="请选择">
            <el-option v-for="type of typeList" :key="type.id" :label="type.typeName" :value="type.id" />
          </el-select>
        </el-form-item>
        <br>
        <el-form-item label="推荐展示">
          <el-switch v-model="productForm.isRecommend"></el-switch>
        </el-form-item>
        <br>
        <el-form-item label="产品图片">
          <el-upload class="avatar-uploader" :auto-upload="false" :on-change="beforeUpload" :show-file-list="false" action="#">
            <img v-if="productForm.viewPic" :src="productForm.viewPic" class="avatar">
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="visible = false">取 消</el-button>
        <el-button :disabled="isEmpty" type="primary" @click="handleSubmit">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog title="修改产品" :visible.sync="updateVisible" width="30%" @closed="handleClosed">
      <el-form :model="productForm" inline>
        <el-form-item label="产品名称">
          <el-input v-model="productForm.productName" type="text" />
        </el-form-item>
        <el-form-item label="产品类型">
          <el-select v-model="productForm.typeId" placeholder="请选择">
            <el-option v-for="type of typeList" :key="type.id" :label="type.typeName" :value="type.id" />
          </el-select>
        </el-form-item>
        <br>
        <el-form-item label="推荐展示">
          <el-switch v-model="productForm.isRecommend"></el-switch>
        </el-form-item>
        <br>
        <el-form-item label="产品图片">
          <img :src="`${$host}:7001/public/${productForm.productPic}`" class="avatar">
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="updateVisible = false">取 消</el-button>
        <el-button :disabled="isEmpty" type="primary" @click="handleSubmit">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
  import axios from 'axios'
  import dayjs from "dayjs";
  export default {
    data() {
      return {
        visible: false,
        updateVisible: false,
        typeList: [],
        productList: [],
        productForm: {
          id: null,
          productName: '',
          typeId: null,
          isRecommend: false,
          productPic: null,
          createTime: null,
          viewPic: null,
        }
      }
    },
    computed: {
      isEmpty() {
        const { productName } = this.productForm
        return !productName
      }
    },
    mounted() {
      this.getTypeList()
      this.getProductList()
    },
    methods: {
      handleAdd() {
        this.visible = true
      },
      handleSubmit() {
        const params = new FormData()
        const { id, productName, typeId, isRecommend, productPic } = this.productForm
        if (!!productName) params.append('productName', productName)
        if (!!typeId) params.append('typeId', typeId)
        params.append('isRecommend', isRecommend)
        if (this.visible) {
          if (!!productPic) params.append('productPic', productPic)
          axios.post('/api/product', params, {headers: {'Content-Type': 'multipart/form-data'}}).then(data => {
            if (data.data === 'success') {
              this.$notify.success('新增成功')
              this.getProductList()
              this.visible = false
            } else {
              this.$notify.error('新增失败')
            }
          })
        }
        if (this.updateVisible) {
          axios.put('/api/product', { id: id, productName: productName, isRecommend: isRecommend, typeId: typeId }).then(data => {
            if (data.data === 'success') {
              this.$notify.success('修改成功')
              this.getProductList()
              this.updateVisible = false
            } else {
              this.$notify.error('修改失败')
            }
          })
        }
      },
      handleEdit(row) {
        this.productForm = { ...row, isRecommend: !!row.isRecommend }
        this.updateVisible = true
      },
      handleDelete(id) {
        axios.delete(`/api/product/${id}`,).then(data => {
          if (data.data === 'success') {
            this.$notify.success('删除成功')
            this.getProductList()
          } else {
            this.$notify.error('删除失败')
          }
        })
      },
      handleClosed() {
        this.productForm = {}
      },
      getTypeList() {
        axios.get('/api/type').then(data => {
          this.typeList = data.data
        })
      },
      getProductList() {
        axios.get('/api/product').then(data => {
          this.productList = data.data
        })
      },
      beforeUpload(event) {
        const { raw, status } = event
        if (status === 'ready') {
          const isJPG = raw.type === 'image/jpeg';
          const isLt2M = raw.size / 1024 / 1024 < 2;
  
          if (!isJPG) {
            // this.$notify.error('上传头像图片只能是 JPG 格式!');
          }
          if (!isLt2M) {
            this.$notify.error('上传头像图片大小不能超过 2MB!');
          }
          if (isJPG || isLt2M) {
            this.productForm.productPic = raw
            this.$set(this.productForm, 'viewPic', URL.createObjectURL(raw))
            // const fileReader = new FileReader()
            // fileReader.onload = (e) => {
            //   this.productForm.productPic = fileReader.result
            // }
            // fileReader.readAsDataURL(raw)
          }
        }
      },
      bufferToImage(buffer) {
        let bytes = new Uint8Array(buffer.data)
        let data = ''
        let len = bytes.byteLength
        for (let i = 0; i < len; i++) {
          data += String.fromCharCode(bytes[i])
        }
        return data
      },
      timeFormatter(row, column, cellValue, index) {
        return dayjs(cellValue).format('YYYY-MM-DD HH:mm:ss')
      }
    }
  }
</script>

<style lang="less">
  .product-page {
    .avatar-uploader .el-upload {
      border: 1px dashed #d9d9d9;
      border-radius: 6px;
      cursor: pointer;
      position: relative;
      overflow: hidden;
    }
    .avatar-uploader .el-upload:hover {
      border-color: #409EFF;
    }
    .avatar-uploader-icon {
      font-size: 28px;
      color: #8c939d;
      width: 178px;
      height: 178px;
      line-height: 178px;
      text-align: center;
    }
    .avatar {
      width: 178px;
      height: 178px;
      display: block;
    }
    .mini-pic {
      width: 100px;
      height: 40px;
    }
  }
</style>
